<template>
  <div>
    <v-app-bar app outlined dense>
      <v-menu offset-y open-on-hover>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            class="rounded-lg mx-2 font-weight-bold"
            text
            light
            depressed
            v-bind="attrs"
            v-on="on"
          >
            تغيير اللغة
            <v-icon right> mdi-web </v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-list-item v-for="(item, index) in items" :key="index" link>
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
      <v-spacer></v-spacer>
      <v-app-bar-nav-icon v-if="authenticated" @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
    </v-app-bar>
    <v-navigation-drawer right app v-model="drawer" v-if="authenticated">
      <v-sheet class="d-flex justify-center align-center" height="200">
        <v-img width="100" height="100" contain src="@/assets/logo.svg"></v-img>
      </v-sheet>
      <v-list class="pa-0">
        <v-list-item
          link
          v-for="item in navItems"
          :key="item.title"
          :to="item.path"
        >
          <v-list-item-content>
            <v-list-item-title
              ><p class="ma-0 text-end">{{ item.title }}</p></v-list-item-title
            >
          </v-list-item-content>
        </v-list-item>
        <v-list-item link>
          <v-list-item-content>
            <v-list-item-title
              ><p class="ma-0 text-end">الدردشة</p></v-list-item-title
            >
          </v-list-item-content>
        </v-list-item>
        <v-list-item link @click="Logout">
          <v-list-item-content>
            <v-list-item-title
              ><p class="ma-0 text-end">تسجيل خروج</p></v-list-item-title
            >
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
  </div>
</template>

<script>
import { db } from "../db";
import { mapActions,mapGetters } from "vuex";
export default {
  data: () => ({
    drawer: null,

    navItems: [
      { title: "الرئيسية", path: "/home" },
      { title: "الملف الشخصي", path: "/profile" },
      { title: "المنتجات", path: "/products" },
      { title: "المنشورات", path: "/posts" },
    ],
    items: [
      { title: "اللغة العربية", value: "ar" },
      { title: "English", value: "en" },
    ],
  }),
  computed: {
    ...mapGetters({
      user: "user",
      authenticated: "authenticated",
    }),
  },
  methods: {
    ...mapActions({
      fetchUser: "fetchUser",
    }),
    Logout() {
      db.auth()
        .signOut()
        .then(() => {
          this.fetchUser(null);
          this.$router.replace("/");
        });
    },
  },
};
</script>
