<template>
  <v-app>
    <NavigationBar  />
    <v-main>
      <router-view />
    </v-main>
  </v-app>
</template>
<script>
import NavigationBar from "./components/NavigationBar";
import { mapGetters } from "vuex";

export default {
  name: "App",
  components: {
    NavigationBar,
  },
  data: () => ({}),
  computed: {
    ...mapGetters({
      user: "user",
      authenticated: "authenticated",
    }),
  },
};
</script>
<style>
#app {
  background-color: #F1EFEF;
}
</style>
