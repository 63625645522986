import Vue from "vue";
import Vuetify from "vuetify/lib/framework";

Vue.use(Vuetify);

export default new Vuetify({

    theme: {
        themes: {
            light: {
                secondary: "#333333", // #FFCDD2
                gold: "#B99503", // #FFCDD2
                dark_gold: "#AE821E", // #FFCDD2
                dark_button: "#242525", // #FFCDD2
                dark_grey: "#555555", // #FFCDD2
                background: "#F1EFEF", // #FFCDD2
                
                facebook: "#3b5998", // #FFCDD2
                instagram: "#bc2a8d", // #FFCDD2
                whatsapp: "#128c7e", // #FFCDD2
                
            },
        },
    },
});
