import Vue from "vue";
import VueRouter from "vue-router";
Vue.use(VueRouter);
import store from "@/store";

const routes = [
  {
    path: "/",
    name: "login",
    component: () => import(/* webpackChunkName: "login" */ "../views/Authentication/Login.vue"),
    beforeEnter: (to, from, next) => {
      if (store.getters["authenticated"]) {
        return next({
          name: "home",
        });
      }
      next();
    },
  },
  {
    path: "/register",
    name: "register",
    component: () => import(/* webpackChunkName: "register" */ "../views/Authentication/Register.vue"),
    beforeEnter: (to, from, next) => {
      if (store.getters["authenticated"]) {
        return next({
          name: "home",
        });
      }
      next();
    },
  },
  {
    path: "/register/credentials",
    name: "register_credentials",
    component: () => import(/* webpackChunkName: "register_credentials" */ "../views/Authentication/RegisterCredentials.vue"),
    beforeEnter: (to, from, next) => {
      if (store.getters["authenticated"]) {
        return next({
          name: "home",
        });
      }
      if (!store.getters["seller/formStore"]) {
        return next({
          name: "register",
        });
      }
      next();
    },
  },
  {
    path: "/home",
    name: "home",
    component: () => import(/* webpackChunkName: "home" */ "../views/Home.vue"),
    beforeEnter: (to, from, next) => {
      if (!store.getters["authenticated"]) {
        return next({
          name: "login",
        });
      }
      next();
    },
  },
  {
    path: "/profile",
    name: "profile",
    component: () => import(/* webpackChunkName: "profile" */ "../views/Profile.vue"),
    beforeEnter: (to, from, next) => {
      if (!store.getters["authenticated"]) {
        return next({
          name: "login",
        });
      }
      next();
    },
  },
  {
    path: "/profile-edit",
    name: "profileEdit",
    component: () => import(/* webpackChunkName: "profileEdit" */ "../views/ProfileEdit.vue"),
    beforeEnter: (to, from, next) => {
      if (!store.getters["authenticated"]) {
        return next({
          name: "login",
        });
      }
      next();
    },
  },
  {
    path: "/products",
    name: "products",
    component: () => import(/* webpackChunkName: "products" */ "../views/Products.vue"),
    beforeEnter: (to, from, next) => {
      if (!store.getters["authenticated"]) {
        return next({
          name: "login",
        });
      }
      next();
    },
  },
  {
    path: "/posts",
    name: "posts",
    component: () => import(/* webpackChunkName: "posts" */ "../views/Posts.vue"),
    beforeEnter: (to, from, next) => {
      if (!store.getters["authenticated"]) {
        return next({
          name: "login",
        });
      }
      next();
    },
  },

];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});


export default router;
