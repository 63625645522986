export default {
  namespaced: true,

  state: {
    sellerForm: null,
    formStore: null
  },
  getters: {
    sellerForm(state) {
      return state.sellerForm
    },
    formStore(state) {
      return state.formStore
    },

  },
  mutations: {
    SET_SELLER_FORM(state, value) {
      state.sellerForm = value;
    },
    SET_FORM_STORE(state, data) {
      state.formStore = data;
    }
  },
  actions: {
    async setSellerForm({ commit }, value) {
      commit('SET_SELLER_FORM', value)
    },
    async setFormStore({ commit }, value) {
      commit('SET_FORM_STORE', value)
    },
    async ClearData({ commit }) {
      commit('SET_SELLER_FORM', null)
      commit('SET_FORM_STORE', null)
    }
  },
}
