import firebase from 'firebase'
import 'firebase/firestore'
import 'firebase/auth'
import 'firebase/storage'
// Get a Firestore instance
var config = {
    apiKey: "AIzaSyAy0pxgLVRxpzTCSD65Kg6EWnqikFxtfKQ",
    authDomain: "mamnon-tajer.firebaseapp.com",
    projectId: "mamnon-tajer",
    storageBucket: "mamnon-tajer.appspot.com",
    messagingSenderId: "728530830905",
    appId: "1:728530830905:web:4c9cbacdb581d7929befe8",
    measurementId: "G-CSG22PF0PS"
}
export const db = firebase.initializeApp(config)
export const firebaseApp = firebase
db.auth().onAuthStateChanged(user => {
    if (user) {
        localStorage.setItem('user', JSON.stringify(user))
    }
    else {
        localStorage.removeItem('user')
    }
});
export var secondaryApp = firebase.initializeApp(config, "Secondary");
const { Timestamp, GeoPoint } = firebase.firestore
export { Timestamp, GeoPoint }
